@tailwind base;
@tailwind components;
@tailwind utilities;


a.active{
  @apply bg-neutral-600 font-medium border-b-2 border-yellow-400;
}

textarea[type="text"]:focus ~ .input-text,
input[type="text"]:focus ~ .input-text,
input[type="password"]:focus ~ .input-text,
input[type="email"]:focus ~ .input-text,
textarea[type="text"]:not(:placeholder-shown) ~ .input-text,
input[type="text"]:not(:placeholder-shown) ~ .input-text,
input[type="password"]:not(:placeholder-shown) ~ .input-text,
input[type="email"]:not(:placeholder-shown) ~ .input-text{
  @apply text-gray-500 bg-white transform -translate-y-7 -translate-x-4 scale-75 rounded-md;
}

textarea[type="text"]:placeholder-shown ~ .input-text,
input[type="text"]:placeholder-shown ~ .input-text,
input[type="password"]:placeholder-shown ~ .input-text,
input[type="email"]:placeholder-shown ~ .input-text {
  @apply hidden;
} 

.mySelect__value-container {
  height: 2.8rem;
}

.special_h{
  height: 80vh;
}